import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import Routing from './routes';

const Route = () => {
    return (
    <BrowserRouter>
        <Routing />
    </BrowserRouter>
)}

export default Route;
