import { configureStore } from "@reduxjs/toolkit";
import logger from 'redux-logger';

import tasksReducer from './tasks/tasks';
import workspaceReducer from "./workspace/workspace.slice";

export const store = configureStore({
    reducer: {
        tasks: tasksReducer,
        workspace: workspaceReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }).concat(logger),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;
