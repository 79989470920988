import {initializeApp} from 'firebase/app';
import {getAuth} from 'firebase/auth';
import {getFirestore} from 'firebase/firestore';
import {getAnalytics} from 'firebase/analytics';
import {getStorage} from 'firebase/storage';
import {getDatabase} from 'firebase/database';

import {firebaseConfig} from './firebase-config';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const database = getDatabase(app);

export {auth, db, analytics, storage, database};
