import React, { useEffect, useState, forwardRef, memo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { UserIcon } from "@heroicons/react/outline";
import { User, onAuthStateChanged } from "firebase/auth";
import { Popover, ArrowContainer } from "react-tiny-popover";

import Profile from "../../pages/profile/profile";
import { auth } from "../../firebase/firebase";
import { toggleNavbar } from "../../redux/tasks/tasks";

import { ReactComponent as MenuIcon } from "../../assets/icons/ic_line_menu.svg";
import SuperTasksMenu from "../../assets/images/menu.png";
import Switcher from "../theme-switcher/theme-switcher";

import "./nav-bar.css";
import { RootState } from "../../redux/store";
import usePremiumStatus from "../../hooks/usePremiumStatus";

const NavBar = forwardRef((props, ref: any) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isUser, setIsUser] = useState<boolean>(false);
  const [user, setUser] = useState<User>();
  const [showNavbar, setShowNavbar] = useState<boolean>(true);
  const [showHomeNavbar, setShowHomeNavbar] = useState<boolean>(true);
  const [profile, setProfile] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [isHover, setIsHover] = useState<boolean>(false);

  const isUserPremium = usePremiumStatus(user);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user !== null) {
        setIsUser(true);
        setUser(user);
      } else {
        setIsUser(false);
      }
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const path = location.pathname?.split("/");
    const id = path[path.length - 1];
    switch (location.pathname) {
      case "/authentication":
        setShowNavbar(false);
        break;
      case "/":
        setShowHomeNavbar(true);
        setShowNavbar(true);
        dispatch(toggleNavbar(false));
        break;
      case "/pricing-plan":
        setShowNavbar(true);
        setShowHomeNavbar(false);
        break;
      case "/about":
        setShowNavbar(true);
        setShowHomeNavbar(false);
        break;
      case "/workspace":
        setShowNavbar(true);
        setShowHomeNavbar(false);
        break;
      case `/workspace/${id}`:
        setShowNavbar(false);
        setShowHomeNavbar(false);
        break;
    }
  }, [location.pathname, dispatch]);

  const navigateAndCloseMenu = (path: string) => {
    navigate(path); // Navigate to the specified path
    setShowMenu(false); // Close the menu
  };

  const onHover = () => {
    if (!isUserPremium) {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  };

  const onHoverLeft = () => {
    setIsHover(false);
  };

  const HamburgerMenu = () => (
    <div
      className={
        showMenu
          ? "showMenuNav title-menu-cliqueraft dark:bg-dark h-full"
          : "hideMenuNav title-menu-cliqueraft"
      }
    >
      <div className="absolute top-0 left-0 px-8 py-8 text-black p-1 flex flex-col">
        <div className="flex flex-row">
          <img
            alt="supertasks.io menu"
            src={SuperTasksMenu}
            width={20}
            className="animate-rotate"
          />
          <p className="ml-5 text-md md:text-2xl font-poppins-medium dark:text-white">
            supertasks.io
          </p>
        </div>
        <span className="text-xs text-do-later bg-do-later-alpha px-3 py-2 rounded-md mt-2 font-poppins-regular">
          Quick decision making tool
        </span>
      </div>
      <div
        className="absolute top-0 right-0 px-8 py-8"
        onClick={() => setShowMenu(false)}
      >
        <svg
          className="h-8 w-8 text-gray-600"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18" />
          <line x1="6" y1="6" x2="18" y2="18" />
        </svg>
      </div>
      <ul className="flex flex-col items-center justify-between min-h-[250px] font-poppins-medium">
        <li className="my-5">
          {isUser ? (
            <div
              className="h-8 w-8 bg-slate-100 rounded-full p-1"
              onClick={() => setProfile(true)}
            >
              <UserIcon />
            </div>
          ) : (
            <div
              onClick={() => navigateAndCloseMenu("/authentication")}
              className="text-black dark:text-white p-1"
            >
              Login
            </div>
          )}
        </li>
        <li className="my-5">
          <div
            onClick={() => navigateAndCloseMenu("/")}
            className="text-black dark:text-white flex items-center no-underline py-0 px-4 h-full active:text-active-link"
          >
            Home
          </div>
        </li>
        {isUser && (
          <li className="my-5">
            <div
              onClick={() => navigateAndCloseMenu("/workspace")}
              className="text-black dark:text-white flex items-center no-underline py-0 px-4 h-full active:text-active-link"
            >
              Workspaces
            </div>
          </li>
        )}
        <li className="my-5">
          <div
            onClick={() => navigateAndCloseMenu("/about")}
            className="text-black dark:text-white flex items-center no-underline py-0 px-4 h-full active:text-active-link"
          >
            About
          </div>
        </li>
        <li className="my-5">
          <div
            onClick={() => navigateAndCloseMenu("/pricing-plan")}
            className="text-black dark:text-white flex items-center no-underline py-0 px-4 h-full active:text-active-link"
          >
            Pricing
          </div>
        </li>
        <li className="my-5">
          <a
            href="https://forms.gle/KqsAFY4CLZZnUZDp8"
            target="_blank"
            rel="noreferrer"
            className="text-black dark:text-white flex items-center no-underline py-0 px-4 h-full active:text-active-link"
          >
            Feedback
          </a>
        </li>
      </ul>
      <div className="absolute bottom-5 left-0 px-8 py-8 p-1 flex flex-col w-full">
        <div className="flex flex-row w-full items-center justify-between">
          <span className="text-xs text-white dark:text-black bg-black dark:bg-white rounded-md px-3 py-2 mb-2">
            BETA
          </span>
          <Switcher />
        </div>
      </div>
    </div>
  );

  const HomeNavbar = () => (
    <nav
      className="h-16 flex justify-end p-2 w-full relative dark:bg-dark title-input-cliqueraft"
      style={{ height: 70 }}
    >
      <NavLink to="/" />

      <div
        className="flex items-center font-poppins-medium title-menu-cliqueraft "
        ref={ref}
      >
        <div className="hidden md:flex px-4">
          <Switcher />
        </div>
        <Popover
          isOpen={isHover}
          align="end"
          positions={["bottom"]}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={"#2a2e2d"}
              arrowSize={8}
            >
              <div className="bg-[#2a2e2d] px-4 py-2 rounded-md hover:bg-[#4e5251] cursor-pointer">
                <div className="popover-content text-white text-xs font-poppins-light">
                  Workspaces are a premium feature, Click to subscribe!
                </div>
              </div>
            </ArrowContainer>
          )}
        >
          <NavLink
            to="workspace"
            className={`${
              isUserPremium ? "text-black dark:text-white" : "text-gray-500"
            } hidden md:flex items-center no-underline py-0 px-4 h-full cursor-pointer active:text-active-link`}
            onMouseOver={onHover}
            onMouseLeave={onHoverLeft}
          >
            Workspaces
          </NavLink>
        </Popover>
        <NavLink
          to="about"
          className="text-black dark:text-white hidden md:flex items-center no-underline py-0 px-4 h-full cursor-pointer active:text-active-link"
        >
          About
        </NavLink>
        <NavLink
          to="pricing-plan"
          className="text-black dark:text-white hidden md:flex items-center no-underline py-0 px-4 h-full cursor-pointer active:text-active-link"
        >
          Pricing
        </NavLink>
        <a
          href="https://forms.gle/KqsAFY4CLZZnUZDp8"
          target="_blank"
          rel="noreferrer"
          className="text-black dark:text-white hidden md:flex items-center no-underline py-0 px-4 h-full cursor-pointer active:text-active-link"
        >
          Feedback
        </a>
        <div className="absolute left-4 text-black p-1 flex items-center dark:text-white">
          <img
            src={SuperTasksMenu}
            width={27}
            height={27}
            className="animate-rotate"
            alt="supertasks.io logo"
          />
          <p className="ml-5 text-lg md:text-2xl hidden md:block">
            supertasks.io
          </p>
          <h1 className="text-xs text-do-later bg-do-later-alpha px-3 py-2 rounded-md hidden lg:block ml-3">
            Quick decision making tool
          </h1>
          <span className="text-xs ml-3 text-white bg-black rounded-md px-3 py-2 dark:text-black dark:bg-white">
            BETA
          </span>
        </div>
        {/* <AddTaskInput /> */}
        {isUser ? (
          <div
            className="h-8 w-8 bg-slate-100 rounded-full cursor-pointer p-1 hidden md:block"
            onClick={() => setProfile(true)}
          >
            <UserIcon />
          </div>
        ) : (
          <NavLink
            to="authentication"
            className="text-black dark:text-white cursor-pointer p-1 hidden md:block mr-2"
          >
            Login
          </NavLink>
        )}
        <div className="md:hidden" onClick={() => setShowMenu((prev) => !prev)}>
          <MenuIcon className="dark:text-white" />
        </div>
      </div>
      <HamburgerMenu />
    </nav>
  );

  const OtherNavbar = () => (
    <nav className="h-16 flex justify-end p-2 w-full dark:bg-dark">
      <NavLink to="/" />
      <div className="flex items-center font-poppins-medium">
        <div className="hidden md:flex px-4">
          <Switcher />
        </div>
        <NavLink
          to="/"
          className="text-black dark:text-white hidden md:flex items-center no-underline py-0 px-4 h-full cursor-pointer active:text-active-link"
        >
          Home
        </NavLink>
        <Popover
          isOpen={isHover}
          align="end"
          positions={["bottom"]}
          content={({ position, childRect, popoverRect }) => (
            <ArrowContainer
              position={position}
              childRect={childRect}
              popoverRect={popoverRect}
              arrowColor={"#2a2e2d"}
              arrowSize={8}
            >
              <div className="bg-[#2a2e2d] px-4 py-2 rounded-md hover:bg-[#4e5251] cursor-pointer">
              <div className="popover-content text-white text-xs font-poppins-light">
                Workspaces are a premium feature, Click to subscribe!
              </div>
              </div>
            </ArrowContainer>
          )}>
        <NavLink
          to="workspace"
          className={`${
            isUserPremium ? "text-black dark:text-white" : "text-gray-500"
          } hidden md:flex items-center no-underline py-0 px-4 h-full cursor-pointer active:text-active-link`}
          onMouseOver={onHover}
          onMouseLeave={onHoverLeft}>
          Workspaces
        </NavLink>
        </Popover>
        <NavLink
          to="about"
          className="text-black dark:text-white hidden md:flex items-center no-underline py-0 px-4 h-full cursor-pointer active:text-active-link"
        >
          About
        </NavLink>
        <NavLink
          to="pricing-plan"
          className="text-black dark:text-white hidden md:flex items-center no-underline py-0 px-4 h-full cursor-pointer active:text-active-link"
        >
          Pricing
        </NavLink>
        <a
          href="https://forms.gle/KqsAFY4CLZZnUZDp8"
          target="_blank"
          rel="noreferrer"
          className="text-black dark:text-white hidden md:flex items-center no-underline py-0 px-4 h-full cursor-pointer active:text-active-link"
        >
          Feedback
        </a>
        <div
          className="absolute left-4 text-black p-1 flex items-center"
          onClick={() => navigateAndCloseMenu("/")}
        >
          <img
            src={SuperTasksMenu}
            width={27}
            className="animate-rotate cursor-pointer"
            alt="supertasks.io menu"
          />
          <h1 className="text-xs ml-3 text-do-later bg-do-later-alpha px-3 py-2 rounded-md hidden lg:block">
            Quick decision making tool
          </h1>
          <span className="text-xs ml-3 text-white bg-black dark:text-black dark:bg-white rounded-md px-3 py-2">
            BETA
          </span>
        </div>
        {isUser ? (
          <div
            className="h-8 w-8 bg-slate-100 rounded-full cursor-pointer p-1 hidden md:block mr-2"
            onClick={() => setProfile(true)}
          >
            <UserIcon />
          </div>
        ) : (
          <NavLink
            to="authentication"
            className="text-black dark:text-white cursor-pointer p-1 hidden md:block mr-2"
          >
            Login
          </NavLink>
        )}
        <div
          className="md:hidden cursor-pointer"
          onClick={() => setShowMenu((prev) => !prev)}
        >
          <MenuIcon className="dark:text-white" />
        </div>
      </div>
      <HamburgerMenu />
    </nav>
  );

  const choseNavBar = () => (showHomeNavbar ? <HomeNavbar /> : <OtherNavbar />);

  return (
    <>
      {showNavbar ? choseNavBar() : null}
      <Profile
        isProfileModalVisible={profile}
        onClose={() => setProfile(false)}
      />
    </>
  );
});

export default memo(NavBar);
