import React, { lazy, Suspense, useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import { User, onAuthStateChanged } from "firebase/auth";

import AnalyticsComponent from "../analytics/analytics";
import NavBar from "../components/nav-bar/nav-bar";
import Loader from "../components/loader/loader";
import { auth } from "../firebase/firebase";

const Authentication = lazy(
  () => import("../pages/authentication/authentication")
);
const About = lazy(() => import("../pages/about/about"));
const PageNotFound = lazy(
  () => import("../pages/page-not-found/page-not-found")
);
const PrivacyPolicy = lazy(
  () => import("../pages/privacy-policy/privacy-policy")
);
const TermsCondition = lazy(
  () => import("../pages/terms-condition/terms-condition")
);
const RefundPolicy = lazy(
  () => import("../pages/page-not-found/page-not-found")
);
const ProdHome = lazy(() => import("../pages/prod-home/prod-home"));
const PricingPlans = lazy(() => import("../pages/pricing/pricing"));
const Workspace = lazy(() => import("../pages/workspace/workspace"));
const WorkspaceTasks = lazy(
  () => import("../pages/workspace-tasks/workspace-tasks")
);

const Routing = () => {
  const location = useLocation();

  const [loading, setLoading] = useState<boolean>(false);
  const [userDataLoaded, setUserDataLoaded] = useState<boolean>(true);

  const routesToHideNavBar = ["/"];
  const shouldHideNavBar = routesToHideNavBar.includes(location.pathname);

  const PrivateRoute = () => {
    if (loading) {
      return <Loader loadingMessage="Hold on! It's loading, not napping. Or is it? We may never know." />
    }
    return userDataLoaded ? (
      <Outlet />
    ) : (
      <Navigate to="/authentication" replace />
    );
  };

  useEffect(() => {
    setLoading(true);
    onAuthStateChanged(auth, (user: User | null) => {
      if (user) {
        setUserDataLoaded(true);
      }
      setLoading(false);
    });
  }, []);

  return (
    <Suspense
      fallback={
        <Loader loadingMessage="Hold on! It's loading, not napping. Or is it? We may never know." />
      }
    >
      {!shouldHideNavBar && <NavBar />}
      <AnalyticsComponent />
      <Routes>
        <Route path="/" element={<ProdHome />} />
        <Route path="/authentication" element={<Authentication />} />
        <Route path="/workspace" element={<PrivateRoute />}>
          <Route path="/workspace" element={<Workspace />} />
          <Route path="/workspace/:id" element={<WorkspaceTasks />} />
        </Route>
        <Route path="/about" element={<About />} />
        <Route path="/terms-condition" element={<TermsCondition />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/refund-policy" element={<RefundPolicy />} />
        <Route path="/pricing-plan" element={<PricingPlans />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
};

export default Routing;
